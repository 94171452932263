import React, { useMemo } from "react"
import { cn } from "@daybridge/cn"
import { DateTime } from "luxon"
import { useTranslations } from "next-intl"
import { Icon } from "@daybridge/components"
import { useTimeZone } from "../../../../../_providers/TimeZoneProvider"
import { useTimeFormatter } from "../../../../../../../../lib/useTimeFormatter"
import { IconId } from "../../../../../../../../data/_gen/types"

type StartEndTimeProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  start: DateTime
  end: DateTime

  continuedFromPreviousDay?: boolean
  continuesToNextDay?: boolean

  allDay?: boolean
  inert?: boolean
}

const StartEndTimeFn = React.forwardRef(
  (props: StartEndTimeProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      start,
      end,
      continuedFromPreviousDay,
      continuesToNextDay,
      allDay,
      inert,
      ...rest
    } = props

    const formatter = useTimeFormatter()

    const t = useTranslations("dates_and_times")

    const runsForWholeDay = continuedFromPreviousDay && continuesToNextDay
    const { effective } = useTimeZone()
    const startInEffectiveZone = useMemo(
      () => start.setZone(effective),
      [start, effective],
    )
    const endInEffectiveZone = useMemo(
      () => end.setZone(effective),
      [end, effective],
    )

    const content = useMemo(() => {
      if (runsForWholeDay) {
        return <span className="w-full truncate">{t("all_day")}</span>
      }

      if (continuedFromPreviousDay) {
        return (
          <span className={cn("w-full truncate")}>
            {t("until", {
              time: formatter(endInEffectiveZone, {
                hour: "numeric",
                minute: "2-digit",
              }),
            })}
          </span>
        )
      }

      if (continuesToNextDay) {
        return (
          <span className={cn("w-full truncate")}>
            {t("from", {
              time: formatter(startInEffectiveZone, {
                hour: "numeric",
                minute: "2-digit",
              }),
            })}
          </span>
        )
      }

      return (
        <>
          <span className={cn("max-w-full truncate mr-1")}>
            {formatter(startInEffectiveZone, {
              hour: "numeric",
              minute: "2-digit",
            })}
          </span>

          <Icon className="w-2 mr-1" name={IconId.ArrowRight} />

          <span className={cn("max-w-full truncate mr-1")}>
            {formatter(endInEffectiveZone, {
              hour: "numeric",
              minute: "2-digit",
            })}
          </span>
        </>
      )
    }, [
      runsForWholeDay,
      continuedFromPreviousDay,
      continuesToNextDay,
      startInEffectiveZone,
      endInEffectiveZone,
      t,
      formatter,
    ])

    if (allDay) {
      return null
    }

    return (
      <div
        ref={ref}
        className={cn(
          "z-10",
          "flex flex-row flex-shrink-0",
          "text-left",
          "font-medium",
          "truncate",
          "opacity-80",
          className,
        )}
        {...rest}
      >
        {content}
      </div>
    )
  },
)
StartEndTimeFn.displayName = "StartEndTime"

export const StartEndTime = React.memo(StartEndTimeFn) as typeof StartEndTimeFn
