import { Area } from "../areas/Area"
import { Calendar } from "../calendars/Calendar"

interface ItemArea {
  area?: Area
  calendar?: Calendar
}

export const resolveItemArea = (item: ItemArea): Area | undefined => {
  return (
    item.area ??
    item.calendar?.defaultArea ??
    item.calendar?.account?.defaultArea ??
    undefined
  )
}

export const getItemHue = (item: ItemArea): number | undefined => {
  const area = resolveItemArea(item)
  return area?.hue
}
