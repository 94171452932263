import React, { Dispatch, SetStateAction, useCallback } from "react"
import { cn } from "@daybridge/cn"
import { Icon } from "@daybridge/components"

type TaskCheckboxProps = React.HTMLAttributes<HTMLDivElement> & {
  checked: boolean
  setChecked: Dispatch<SetStateAction<boolean>>

  active?: boolean
  setActive?: Dispatch<SetStateAction<boolean>>

  theme?: "adaptive" | "neutral"
}

const TaskCheckboxFn = React.forwardRef(
  (props: TaskCheckboxProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      className,
      checked,
      setChecked,
      active,
      setActive,
      theme,
      ...rest
    } = props

    const onTickboxKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
          setChecked?.((checked) => !checked)
          e.stopPropagation()
          e.preventDefault()
        }
      },
      [setChecked],
    )

    const onTickboxClick = useCallback(
      (e: React.MouseEvent) => {
        setChecked((checked) => !checked)
        e.stopPropagation()
      },
      [setChecked],
    )

    const onTickboxMouseUp = useCallback(() => {
      setActive?.(false)
    }, [setActive])

    const onTickboxMouseDown = useCallback(() => {
      setActive?.(true)
      window.addEventListener("mouseup", onTickboxMouseUp)
      return () => {
        window.removeEventListener("mouseup", onTickboxMouseUp)
      }
    }, [onTickboxMouseUp, setActive])

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        ref={ref}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onClick={onTickboxClick}
        onKeyDown={onTickboxKeyDown}
        onMouseDown={onTickboxMouseDown}
        onMouseUp={onTickboxMouseUp}
        className={cn(
          "rounded-md",
          "transition-[background] duration-200",
          checked && theme === "adaptive"
            ? "bg-adaptive-primary shadow"
            : checked
            ? "bg-primary shadow"
            : theme === "adaptive"
            ? "shadow-inner shadow-adaptive-tint-heavy/50 dark:shadow-background/10"
            : "shadow-inner shadow-tint-heavy/50 dark:shadow-background/10",
          "border",
          "focus-visible:ring-2 ring-offset-2",
          theme === "adaptive"
            ? "ring-adaptive-primary border-adaptive-tint-extra-heavy"
            : "ring-primary border-tint-extra-heavy",
          "flex items-center justify-center",
          className,
        )}
        {...rest}
      >
        {checked && <Icon name="Tick" className="w-[55%] text-white" />}
      </div>
    )
  },
)
TaskCheckboxFn.displayName = "TaskCheckbox"

export const TaskCheckbox = React.memo(TaskCheckboxFn) as typeof TaskCheckboxFn
