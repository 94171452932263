import React from "react"
import { cn } from "@daybridge/cn"
import { TimedItemProps } from "../_layouts/TimedItem"
import { ItemIcon } from "../_common/ItemIcon"
import { styleForHue } from "../../../../../../../../lib/styleForHue"
import { ResizeHandle } from "../_common/ResizeHandle"
import { Duration } from "../_common/Duration"
import { getItemHue } from "../../../../../../../../data/items/itemArea"

export const MarkerTimed = React.forwardRef(
  (props: TimedItemProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      item,
      inert,
      cancelledText,
      declinedText,
      noTitleText,
      iconReplacement,
      continuedFromPreviousDay,
      continuesToNextDay,
      onResizeStart,
      ...rest
    } = props

    return (
      <button
        ref={ref}
        className={cn(
          "absolute top-0 bottom-0",
          "w-2 hover:w-auto max-w-full",
          "pointer-events-auto",
          "flex items-start",
          "rounded-lg",

          // Focus ring
          !inert && [
            "focus-visible:ring-2",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "focus-visible:ring-primary"
              : "focus-visible:ring-adaptive-primary",
            "focus-visible:ring-offset-surface",
            "focus-visible:ring-offset-2",
          ],

          // Hover effect
          "hover:brightness-[1.01] dark:hover:brightness-110",

          // Transitions
          "transition-[border-radius,filter,box-shadow] duration-200",

          "group",

          className,
        )}
        style={{
          ...styleForHue(getItemHue(item)),
        }}
        tabIndex={inert ? -1 : 0}
        aria-hidden={inert}
        aria-label={item.title}
        {...rest}
      >
        <div
          className={cn(
            "absolute top-0 bottom-0 -left-px",
            "w-0.5",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "bg-primary"
              : "bg-adaptive-primary",
          )}
        />

        {item.icon && (
          <ItemIcon
            name={item.icon}
            theme={getItemHue(item) === undefined ? "neutral" : "adaptive"}
            className="absolute top-0 -left-2.5 w-5 z-20"
          />
        )}

        <div
          className={cn(
            "relative",
            "-top-0.5 -left-3",
            "z-10",
            "w-0 group-hover:w-fit max-w-full",
            "pl-7 pr-2.5 py-1",
            "min-w-0",
            "ring-1 ring-inset",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "ring-tint-heavy"
              : "ring-adaptive-tint-heavy",
            "rounded-full",
            "text-sm",
            "flex flex-row",
            "truncate",

            "opacity-0 group-hover:opacity-100",
            "transition-[opacity,width] duration-200",

            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "text-medium-contrast"
              : "text-adaptive-medium-contrast",

            // Background
            "bg-gradient-to-b",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "from-object-highlight to-object"
              : "from-adaptive-object-highlight to-adaptive-object",
          )}
        >
          <div
            className={cn(
              "flex-1",
              "truncate",
              (item.cancelled || item.declined) &&
                "line-through decoration-2 decoration-low-contrast",
            )}
          >
            {item.title || `(${noTitleText})`}
          </div>
          {!item.cancelled && !item.declined && (
            <Duration
              className={cn(
                "ml-1",
                item.cancelled ||
                  item.declined ||
                  getItemHue(item) === undefined
                  ? "text-low-contrast"
                  : "text-adaptive-medium-contrast/60",
                "flex-shrink-0",
              )}
              item={item}
            >
              &bull;{" "}
            </Duration>
          )}
        </div>

        <div className="absolute top-0 bottom-0 -left-2 w-4">
          {onResizeStart && !continuedFromPreviousDay && !inert && (
            <ResizeHandle
              side="top"
              onMouseDown={(e) => {
                if (e.button === 0) {
                  onResizeStart(e, "start")
                }
              }}
              className="z-50"
            />
          )}
          {onResizeStart && !continuesToNextDay && !inert && (
            <ResizeHandle
              side="bottom"
              onMouseDown={(e) => {
                if (e.button === 0) {
                  onResizeStart(e, "end")
                }
              }}
              className="z-50"
            />
          )}
        </div>
      </button>
    )
  },
)
MarkerTimed.displayName = "MarkerTimed"
