import React from "react"
import { cn } from "@daybridge/cn"
import { TimedItemProps } from "../_layouts/TimedItem"
import { ResizeHandle } from "../_common/ResizeHandle"
import { styleForHue } from "../../../../../../../../lib/styleForHue"
import { ItemIcon } from "../_common/ItemIcon"
import { Duration } from "../_common/Duration"
import { getItemHue } from "../../../../../../../../data/items/itemArea"

export const TravelTimed = React.forwardRef(
  (props: TimedItemProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      item,
      inert,
      cancelledText,
      declinedText,
      noTitleText,
      iconReplacement,
      continuedFromPreviousDay,
      continuesToNextDay,
      onResizeStart,
      ...rest
    } = props

    return (
      <button
        ref={ref}
        className={cn(
          "absolute inset-0",
          "flex flex-row",
          "container-min-h-[60px]/placement:flex-col",
          "items-center justify-center",
          "rounded-lg",
          "ml-2",
          inert ? "pointer-events-none" : "pointer-events-auto",
          "group",

          // Additional classes
          className,
        )}
        style={{
          ...styleForHue(getItemHue(item)),
        }}
        tabIndex={inert ? -1 : 0}
        aria-hidden={inert}
        aria-label={item.title}
        {...rest}
      >
        {item.icon && (
          <ItemIcon
            name={item.icon}
            theme={getItemHue(item) === undefined ? "neutral" : "adaptive"}
            className={cn(
              "w-5 h-5 z-50",
              "container-max-h-[23px]/placement:hidden",
              "container-max-h-[59px]/placement:-mr-5",
              "container-min-h-[60px]/placement:-mb-1",
            )}
          />
        )}

        <div
          className={cn(
            "px-2.5 py-1",
            "max-w-full",
            "min-w-0",
            "ring-1 ring-inset",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "ring-tint-heavy"
              : "ring-adaptive-tint-heavy",
            "rounded-full",
            "text-sm",
            "z-20",
            "flex flex-row",
            "truncate",

            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "text-medium-contrast"
              : "text-adaptive-medium-contrast",

            "container-max-h-[23px]/placement:hidden",
            "container-max-h-[59px]/placement:pl-7",
            "container-max-h-[32px]/placement:text-xs",

            // Background
            "bg-gradient-to-b",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "from-object-highlight to-object"
              : "from-adaptive-object-highlight to-adaptive-object",

            // Focus ring
            !inert && [
              "group-focus-visible:ring-2",
              getItemHue(item) === undefined
                ? "group-focus-visible:ring-primary"
                : "group-focus-visible:ring-adaptive-primary",
              "group-focus-visible:ring-offset-surface",
            ],

            "transition-[border-radius,filter,box-shadow] duration-200",
          )}
        >
          <div
            className={cn(
              "flex-1",
              "truncate",
              (item.cancelled || item.declined) &&
                "line-through decoration-2 decoration-low-contrast",
            )}
          >
            {item.title || `(${noTitleText})`}
          </div>
          {!item.cancelled && !item.declined && (
            <Duration
              className={cn(
                "ml-1",
                item.cancelled ||
                  item.declined ||
                  getItemHue(item) === undefined
                  ? "text-low-contrast"
                  : "text-adaptive-medium-contrast/60",
                "flex-shrink-0",
                "container-max-w-[164px]/placement:hidden",
              )}
              item={item}
            >
              &bull;{" "}
            </Duration>
          )}
        </div>

        <div
          className={cn(
            "absolute",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "bg-primary"
              : "bg-adaptive-primary",
            "top-0 left-1/2 -translate-x-1/2",
            "w-2 h-1.5",
            "rounded-b-full",
          )}
        />
        <div
          className={cn(
            "absolute",
            "border-dashed",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "border-primary/50"
              : "border-adaptive-primary/50",
            "top-0 left-1/2 -translate-x-1/2",
            "h-full",
            "border-l-2",
          )}
        />
        <div
          className={cn(
            "absolute",
            item.cancelled || item.declined || getItemHue(item) === undefined
              ? "bg-primary"
              : "bg-adaptive-primary",
            "bottom-0 left-1/2 -translate-x-1/2",
            "w-2 h-1.5",
            "rounded-t-full",
          )}
        />

        {onResizeStart && !continuedFromPreviousDay && !inert && (
          <ResizeHandle
            side="top"
            onMouseDown={(e) => {
              if (e.button === 0) {
                onResizeStart(e, "start")
              }
            }}
          />
        )}
        {onResizeStart && !continuesToNextDay && !inert && (
          <ResizeHandle
            side="bottom"
            onMouseDown={(e) => {
              if (e.button === 0) {
                onResizeStart(e, "end")
              }
            }}
          />
        )}
      </button>
    )
  },
)
TravelTimed.displayName = "TravelTimed"
