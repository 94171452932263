import { useLocale } from "next-intl"
import { useCallback, useMemo } from "react"
import { humanizer, Options } from "humanize-duration"
import { Duration } from "luxon"

export const useDurationFormatter = () => {
  const locale = useLocale()

  const h = useMemo(
    () =>
      humanizer({
        language: locale,
        languages: {
          "short-en-US": {
            y: () => "y",
            mo: () => "mo",
            w: () => "w",
            d: () => "d",
            h: () => "h",
            m: () => "m",
          },
          "short-en-GB": {
            y: () => "y",
            mo: () => "mo",
            w: () => "w",
            d: () => "d",
            h: () => "h",
            m: () => "m",
          },
          "short-de": {
            y: () => "J",
            mo: () => "M",
            w: () => "W",
            d: () => "T",
            h: () => "h",
            m: () => "m",
          },
          "short-es": {
            y: () => "a",
            mo: () => "m",
            w: () => "sem",
            d: () => "d",
            h: () => "h",
            m: () => "m",
          },
          "short-fr": {
            y: () => "a",
            mo: () => "mo",
            w: () => "sem",
            d: () => "j",
            h: () => "h",
            m: () => "m",
          },
          "short-it": {
            y: () => "a",
            mo: () => "m",
            w: () => "sett",
            d: () => "g",
            h: () => "h",
            m: () => "m",
          },
          "short-nl": {
            y: () => "j",
            mo: () => "mnd",
            w: () => "wk",
            d: () => "d",
            h: () => "u",
            m: () => "m",
          },
          "short-pt": {
            y: () => "a",
            mo: () => "m",
            w: () => "sem",
            d: () => "d",
            h: () => "h",
            m: () => "m",
          },
        },
      }),
    [locale],
  )

  return useCallback(
    (duration: Duration, short = true, options?: Omit<Options, "language">) => {
      return h(duration.as("milliseconds"), {
        round: true,
        conjunction: " ",
        spacer: "",
        serialComma: false,
        language: short ? `short-${locale}` : locale,
        ...options,
      })
    },
    [h, locale],
  )
}
