import React from "react"
import { useDurationFormatter } from "../../../../../../../../lib/useDurationFormatter"
import { durationIgnoringZoneDifferences } from "../../../../../../../../lib/datetime"
import { Item } from "../../../../../../../../data/items/types"

type DurationProps = React.HTMLAttributes<HTMLSpanElement> & {
  item: Omit<Item, "calendar">
}

const DurationFn = React.forwardRef(
  (props: DurationProps, ref: React.ForwardedRef<HTMLSpanElement>) => {
    const { children, item, ...rest } = props
    const formatter = useDurationFormatter()

    return (
      <span ref={ref} {...rest}>
        {children}
        {formatter(
          item.allDay
            ? durationIgnoringZoneDifferences(item.start, item.end)
            : item.end.diff(item.start),
          true,
          {
            units: ["d", "h", "m"],
          },
        )}
      </span>
    )
  },
)
DurationFn.displayName = "Duration"

export const Duration = React.memo(DurationFn) as typeof DurationFn
