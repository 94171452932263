import React from "react"
import { cn } from "@daybridge/cn"
import { TimedItemProps } from "../_layouts/TimedItem"
import { TimedItemCenterAligned } from "../_layouts/TimedItemCenterAligned"

export const BlockTimed = React.forwardRef(
  (props: TimedItemProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { className, ...rest } = props
    return (
      <TimedItemCenterAligned
        ref={ref}
        className={cn(
          "-top-px -left-px -right-px",
          "from-background to-background",
          "rounded-t-none rounded-b-none",
          "text-medium-contrast",
          "[&_.item-ring]:rounded-r-none [&_.item-ring]:rounded-l-none [&_.item-ring]:ring-tint",
          "[&_.item-times>*]:decoration-medium-contrast/50",
          className,
        )}
        {...rest}
      >
        <div
          className={cn(
            "absolute inset-0 z-0",
            "pointer-events-none",
            "bg-[repeating-linear-gradient(-45deg,_transparent,_transparent_6px,_oklch(var(--color-adaptive-tint-light)_var(--hue,_220)_/_0.01)_6px,_oklch(var(--color-adaptive-tint-light)_var(--hue,_220)_/_0.01)_12px)]",
          )}
        />
      </TimedItemCenterAligned>
    )
  },
)
BlockTimed.displayName = "BlockTimed"
