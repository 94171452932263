import React from "react"
import { cn } from "@daybridge/cn"
import { cva, VariantProps } from "class-variance-authority"

type ResizeHandleProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof resizeHandleVisualIndicator> & {
    // Additional props here...
  }

const resizeHandleMouseActivationArea = cva(
  ["absolute", "group/handle", "flex items-center justify-center"],
  {
    variants: {
      side: {
        top: [
          "left-1/2 -translate-x-1/2",
          "top-0",
          "w-1/2",
          "h-[min(50%,0.5rem)]",
          "cursor-ns-resize",
        ],
        bottom: [
          "left-1/2 -translate-x-1/2",
          "bottom-0",
          "w-1/2",
          "h-[min(50%,0.5rem)]",
          "cursor-ns-resize",
        ],
        left: [
          "top-1/2 -translate-y-1/2",
          "left-0",
          "h-1/2",
          "w-[min(50%,1rem)]",
          "cursor-ew-resize",
        ],
        right: [
          "top-1/2 -translate-y-1/2",
          "right-0",
          "h-1/2",
          "w-[min(50%,1rem)]",
          "cursor-ew-resize",
        ],
      },
    },
  },
)

const resizeHandleVisualIndicator = cva(
  [
    "absolute",
    "bg-adaptive-tint-heavy",
    "transition-[opacity,width,height] ease-in-out duration-200",
    "opacity-0 group-hover/handle:opacity-100",
  ],
  {
    variants: {
      side: {
        top: [
          "top-px",
          "w-0 group-hover/handle:w-full",
          "h-[3px]",
          "rounded-b-full",
        ],
        bottom: [
          "bottom-px",
          "w-0 group-hover/handle:w-full",
          "h-[3px]",
          "rounded-t-full",
        ],
        left: [
          "left-px",
          "w-[3px]",
          "h-0 group-hover/handle:h-full",
          "rounded-r-full",
        ],
        right: [
          "right-px",
          "w-[3px]",
          "h-0 group-hover/handle:h-full",
          "rounded-l-full",
        ],
      },
    },
  },
)

const ResizeHandleFn = React.forwardRef(
  (props: ResizeHandleProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, side, ...rest } = props
    return (
      <div
        ref={ref}
        className={cn(resizeHandleMouseActivationArea({ side, className }))}
        {...rest}
      >
        <div className={cn(resizeHandleVisualIndicator({ side }))}>
          {children}
        </div>
      </div>
    )
  },
)
ResizeHandleFn.displayName = "ResizeHandle"

export const ResizeHandle = React.memo(ResizeHandleFn) as typeof ResizeHandleFn
