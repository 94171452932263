import React, { useState } from "react"
import { cn } from "@daybridge/cn"
import { TimedItem, TimedItemProps } from "../_layouts/TimedItem"
import { TaskCheckbox } from "../_common/TaskCheckbox"
import { getItemHue } from "../../../../../../../../data/items/itemArea"

export const TaskTimed = React.forwardRef(
  (props: TimedItemProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { className, ...rest } = props
    const [active, setActive] = useState(false)
    const [checked, setChecked] = useState(false)
    return (
      <TimedItem
        ref={ref}
        iconReplacement={
          <TaskCheckbox
            className={cn(
              "w-5 h-5 mr-2",
              "transition-[transform] duration-200",
              active && "scale-[0.8]",
            )}
            active={active}
            setActive={setActive}
            checked={checked}
            setChecked={setChecked}
            theme={
              getItemHue(props.item) === undefined ? "neutral" : "adaptive"
            }
          />
        }
        className={cn(
          checked && [
            "bg-gradient-to-b from-object-highlight to-object",
            "[&_.item-title]:line-through [&_.item-title]:text-medium-contrast",
            "[&_.item-times]:text-medium-contrast",
            "[&_.item-times>*]:decoration-medium-contrast/50",
          ],
          className,
        )}
        {...rest}
      />
    )
  },
)
TaskTimed.displayName = "TaskTimed"
