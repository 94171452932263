import React from "react"
import { cn } from "@daybridge/cn"
import { Item } from "../../../../../../../../data/items/types"
import { styleForHue } from "../../../../../../../../lib/styleForHue"
import { ResizeHandle } from "../_common/ResizeHandle"
import { StartEndTime } from "../_common/StartEndTime"
import { ItemIcon } from "../_common/ItemIcon"
import { getItemHue } from "../../../../../../../../data/items/itemArea"

export type TimedItemProps = React.HTMLAttributes<HTMLButtonElement> & {
  item: Omit<Item, "calendar">
  inert?: boolean

  // Strings
  cancelledText: string
  noTitleText: string
  declinedText: string

  // Icon replacement
  iconReplacement?: React.ReactNode

  // Continuity - used for rounding and other styles
  continuedFromPreviousDay?: boolean
  continuesToNextDay?: boolean

  // Resizing
  onResizeStart?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    direction: "start" | "end",
  ) => void
}

export const TimedItem = React.forwardRef(
  (props: TimedItemProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      item,
      inert,
      cancelledText,
      declinedText,
      noTitleText,
      iconReplacement,
      continuedFromPreviousDay,
      continuesToNextDay,
      onResizeStart,
      ...rest
    } = props

    const iconCn = cn(
      "w-5",
      "z-10",
      "mr-2",
      !iconReplacement && "hidden container-min-w-[128px]/placement:block",
    )

    return (
      <button
        ref={ref}
        className={cn(
          // Structure
          "absolute inset-0",
          "w-[-moz-available]",
          "flex flex-row",
          "items-center container-min-h-[64px]/placement:items-start",

          "border-x border-surface",
          !continuedFromPreviousDay && "rounded-t-lg border-t",
          !continuesToNextDay && "rounded-b-lg border-b",

          // Padding
          "px-2",
          "container-min-h-[64px]/placement:py-2.5",

          // Background
          "bg-gradient-to-b",
          item.cancelled || item.declined || getItemHue(item) === undefined
            ? "from-object-highlight to-object"
            : "from-adaptive-object-highlight to-adaptive-object",

          // Focus ring
          !inert && [
            "focus-visible:ring-2",
            getItemHue(item) === undefined
              ? "focus-visible:ring-primary"
              : "focus-visible:ring-adaptive-primary",
            "focus-visible:ring-offset-surface",
            "focus-visible:ring-offset-2",
          ],

          // Hover effect
          "hover:brightness-[1.01] dark:hover:brightness-110",

          // Transitions
          "transition-[border-radius,filter,box-shadow,background] duration-200",

          // Font
          "text-xs container-min-h-[12px]/placement:text-sm container-min-h-[20px]/placement:text-base",
          item.cancelled || item.declined || getItemHue(item) === undefined
            ? "text-medium-contrast"
            : "text-adaptive-medium-contrast",

          // Pointer events
          inert ? "pointer-events-none" : "pointer-events-auto",

          // Additional classes
          className,
        )}
        style={{
          ...styleForHue(getItemHue(item)),
        }}
        tabIndex={inert ? -1 : 0}
        aria-hidden={inert}
        aria-label={item.title}
        {...rest}
      >
        {iconReplacement ? (
          iconReplacement
        ) : item.icon ? (
          <ItemIcon
            theme={getItemHue(item) === undefined ? "neutral" : "adaptive"}
            name={item.icon}
            className={iconCn}
          />
        ) : (
          <div className={iconCn} />
        )}
        <div
          className={cn(
            "z-10",
            "flex-1 min-w-0 overflow-hidden",
            "flex flex-row container-min-h-[40px]/placement:flex-col",
            "items-center container-min-h-[40px]/placement:items-start",
          )}
        >
          <div
            className={cn(
              "item-body",
              "w-full",
              "flex-1",
              "text-left",
              "container-max-h-[63px]/placement:truncate",
              "container-min-h-[64px]/placement:line-clamp-2",
              "leading-[1.2]",
              "overflow-hidden",
            )}
          >
            <span
              className={cn(
                "item-title",
                "break-words",
                "decoration-2 decoration-low-contrast",
                (item.cancelled || item.declined) && "line-through",
              )}
            >
              {item.title || `(${noTitleText})`}
            </span>
          </div>
          {item.cancelled || item.declined ? (
            <div
              className={cn("truncate", "text-low-contrast", "leading-none")}
            >
              {item.cancelled ? cancelledText : declinedText}
            </div>
          ) : (
            <StartEndTime
              start={item.start}
              end={item.end}
              continuedFromPreviousDay={continuedFromPreviousDay}
              continuesToNextDay={continuesToNextDay}
              allDay={item.allDay}
              className={cn(
                "item-times",
                "hidden container-min-h-[12px]/placement:flex",
                "text-xs container-min-h-[20px]/placement:text-sm",
                "[&>*:nth-child(n+2)]:hidden [&>*:nth-child(n+2)]:container-min-w-[164px]/placement:container-min-h-[40px]/placement:block",
                getItemHue(item) === undefined
                  ? "[&>*]:decoration-medium-contrast/50"
                  : "[&>*]:decoration-adaptive-medium-contrast/50",
              )}
              inert={inert}
            />
          )}
        </div>

        {children}

        <div
          className={cn(
            "item-ring",
            "absolute inset-0",
            "ring-1 ring-inset",
            getItemHue(item) == undefined ? "ring-tint" : "ring-adaptive-tint",
            "pointer-events-none",
            !continuedFromPreviousDay && "rounded-t-lg",
            !continuesToNextDay && "rounded-b-lg",
          )}
        />

        {onResizeStart && !continuedFromPreviousDay && !inert && (
          <ResizeHandle
            side="top"
            onMouseDown={(e) => {
              if (e.button === 0) {
                onResizeStart(e, "start")
              }
            }}
          />
        )}
        {onResizeStart && !continuesToNextDay && !inert && (
          <ResizeHandle
            side="bottom"
            onMouseDown={(e) => {
              if (e.button === 0) {
                onResizeStart(e, "end")
              }
            }}
          />
        )}
      </button>
    )
  },
)
TimedItem.displayName = "TimedItem"
